import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { dataManager } from './dataManager';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const location = useLocation();

  if (!dataManager.isAuthenticated()) {
    // Save the attempted URL as state in the redirect
    return <Navigate 
      to={{
        pathname: "/login",
        search: `?redirect=${encodeURIComponent(location.pathname)}${location.search}`
      }}
      state={{ from: location }}
      replace 
    />;
  }

  return element;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/dashboard" 
          element={<PrivateRoute element={<Dashboard />} />} 
        />
        <Route 
          path="/dashboard/call/:callId" 
          element={<PrivateRoute element={<Dashboard />} />} 
        />
        <Route 
          path="/dashboard/call/:callId/action/:actionId" 
          element={<PrivateRoute element={<Dashboard />} />} 
        />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
