import axios from 'axios';

import { Call } from './types/Call';
import { Summary } from './types';
import { getCallById as apiGetCallById, getCalls as apiGetCalls } from './api/retellApi';

const API_URL = process.env.REACT_APP_BACKEND_URL || '/api';

export const StorageKeys = {
  USER_EMAIL: 'userEmail',
  IS_SUPERUSER: 'isSuperUser',
  ACCESS_TOKEN: 'accessToken',
} as const;

interface CallsResponse {
  calls: Call[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

class DataManager {
  private calls: Call[] = [];
  private userEmail: string | null = null;
  private currentPage: number = 1;
  private limit: number = 50;
  private totalPages: number = 1;

  public setUserEmail(email: string) {
    localStorage.setItem(StorageKeys.USER_EMAIL, email);
  }

  public getUserEmail(): string | null {
    return localStorage.getItem(StorageKeys.USER_EMAIL);
  }

  public setIsSuperuser(isSuperuser: boolean) {
    localStorage.setItem(StorageKeys.IS_SUPERUSER, isSuperuser.toString());
  }

  public getIsSuperuser(): boolean {
    return localStorage.getItem(StorageKeys.IS_SUPERUSER) === 'true';
  }

  private getToken(): string | null {
    return localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  }

  public isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public setToken(token: string) {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  }

  private getHeaders() {
    return {
      'Authorization': this.getToken(),
    };
  }

  public async getCallById(callId: string): Promise<Call | null> {
    try {
      return await apiGetCallById(callId);
    } catch (error) {
      console.error('Error fetching call:', error);
      return null;
    }
  }

  public async getCalls(page: number = 1, searchTerm: string = ''): Promise<CallsResponse> {
    try {
      const calls = await apiGetCalls();
      // Filter calls if search term is provided
      const filteredCalls = searchTerm
        ? calls.filter(call => 
            call.call_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            call.agent_id?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : calls;

      // Calculate pagination
      const total = filteredCalls.length;
      const totalPages = Math.ceil(total / this.limit);
      const startIndex = (page - 1) * this.limit;
      const endIndex = startIndex + this.limit;
      const paginatedCalls = filteredCalls.slice(startIndex, endIndex);

      this.calls = paginatedCalls;
      this.currentPage = page;
      this.totalPages = totalPages;

      return {
        calls: paginatedCalls,
        total,
        page,
        limit: this.limit,
        totalPages
      };
    } catch (error) {
      console.error('Error fetching calls:', error);
      return { calls: [], total: 0, page: 1, limit: this.limit, totalPages: 1 };
    }
  }

  public async getCallSummary(): Promise<Summary | null> {
    try {
      const response = await axios.get(`${API_URL}/call-summary`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching call summary:', error);
      return null;
    }
  }

  public async getAgentAnalytics(): Promise<any[]> {
    try {
      const response = await axios.get(`${API_URL}/agent-analytics`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching agent analytics:', error);
      return [];
    }
  }

  public async forceUpdate(): Promise<void> {
    try {
      await axios.post(`${API_URL}/update-calls`, {}, {
        headers: this.getHeaders(),
      });
      await this.getCalls(1); // Reset to first page after update
    } catch (error) {
      console.error('Error updating calls:', error);
    }
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public getTotalPages(): number {
    return this.totalPages;
  }

  public getLimit(): number {
    return this.limit;
  }

  private clearStorage() {
    localStorage.removeItem(StorageKeys.USER_EMAIL);
    localStorage.removeItem(StorageKeys.IS_SUPERUSER);
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
  }

  public logout() {
    this.clearStorage();
    window.location.reload();
  }
}

export const dataManager = new DataManager();
