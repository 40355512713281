import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Box, Container, Alert } from '@mui/material';
import { dataManager } from '../dataManager';
import { useNavigate, useLocation } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getRedirectPath = () => {
    const fromState = location.state?.from?.pathname;
    if (fromState) return fromState;
    
    const params = new URLSearchParams(location.search);
    const fromParams = params.get('redirect');
    if (fromParams) return fromParams;
    
    return '/dashboard';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    if (!email || !password) {
      setError('Please enter both email and password');
      setIsLoading(false);
      return;
    }

    try {
      const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      console.log('Attempting login to:', API_URL); // Debug log

      const response = await fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include', // Important for CORS
        body: JSON.stringify({ email, password }),
      });

      console.log('Response status:', response.status); // Debug log

      const data = await response.json();
      console.log('Response data:', { ...data, token: '***' }); // Debug log (hide token)

      if (response.ok) {
        if (data.success) {
          dataManager.setUserEmail(email);
          dataManager.setIsSuperuser(data.isSuperuser);
          dataManager.setToken(data.token);
          
          const redirectPath = getRedirectPath();
          console.log('Redirecting to:', redirectPath); // Debug log
          navigate(redirectPath);
        } else {
          setError('Invalid email or password');
        }
      } else {
        setError(data.error || 'An error occurred during login');
      }
    } catch (error: any) {
      console.error('Login error:', error); // Debug log
      setError(
        error.message === 'Failed to fetch' 
          ? 'Unable to connect to the server. Please check your connection.'
          : 'An error occurred during login. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Login to VoxGenius
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              {isLoading ? 'Signing In...' : 'Sign In'}
            </Button>
          </form>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {process.env.NODE_ENV === 'development' && (
            <Typography variant="caption" display="block" align="center" sx={{ mt: 2 }}>
              API URL: {process.env.REACT_APP_API_URL || 'http://localhost:3001'}
            </Typography>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;