import axios from 'axios';
import { Call } from '../types/Call';

const BASE_URL = 'https://api.retellai.com/v2/';
const API_LIST_CALLS_URL = BASE_URL + 'list-calls';
const API_GET_CALL_URL = BASE_URL + 'get-call';

const API_KEY = "key_40989aaa14642dea0500f309807a";

if (!API_KEY) {
  console.error('API key is missing. Please set REACT_APP_RETELL_API_KEY in your .env file.');
}

// Custom type guard for Axios errors
function isAxiosError(error: any): error is {
  response?: { data: any; status: number };
  request?: any;
  message: string;
} {
  return error && error.isAxiosError === true;
}

export const getCalls = async (): Promise<Call[]> => {
  try {
    const response = await axios.post<Call[]>(
      API_LIST_CALLS_URL,
      {
        filter_criteria: {},
        sort_order: 'descending',
        limit: 100,
        include_web_actions: true // Add this to include web actions
      },
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      console.log('Unexpected response structure. Response is not an array:', response.data);
      return [];
    }
  } catch (error: any) {
    if (isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};

export const getCallById = async (callId: string): Promise<Call | null> => {
  try {
    const response = await axios.get<Call>(
      API_GET_CALL_URL + '/' + callId,
      {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data ?? null;
  } catch (error: any) {
    if (isAxiosError(error)) {
      if (error.response) {
        console.error('Error response from server:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};
