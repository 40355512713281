import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Typography, Box, IconButton,
  Grid, Paper, Accordion, AccordionSummary, AccordionDetails,
  Chip, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar,
  Card, CardContent
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Call, CallAction } from '../types/Call';
import { useNavigate } from 'react-router-dom';
import { dataManager } from '../dataManager';

interface CallDetailsProps {
  call: Call | null;
  onClose: () => void;
  llm_latency?: Record<string, unknown>;
  llm_websocket_network_rtt_latency?: Record<string, unknown>;
  e2e_latency?: Record<string, unknown>;
  selectedActionId?: string;
}

const CallDetails: React.FC<CallDetailsProps> = ({ 
  call, 
  onClose,
  selectedActionId 
}) => {
  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState<CallAction | null>(null);
  const isSuperUser = dataManager.getIsSuperuser();

  useEffect(() => {
    if (call?.call_actions && selectedActionId) {
      const action = call.call_actions.find(action => action.action_id === selectedActionId);
      if (action) {
        setSelectedAction(action);
      }
    } else {
      setSelectedAction(null);
    }
  }, [call, selectedActionId]);

  if (!call) return null;

  const formatDate = (timestamp: number | undefined) => {
    return timestamp ? new Date(timestamp).toLocaleString() : 'N/A';
  };

  const formatDuration = (totalDurationSeconds: number | undefined) => {
    if (totalDurationSeconds === undefined) return 'N/A';
    const minutes = Math.floor(totalDurationSeconds / 60);
    const seconds = Math.floor(totalDurationSeconds % 60);
    return `${minutes}m ${seconds}s`;
  };

  const formatCurrency = (amount?: number | null) => {
    if (amount === null || amount === undefined) return 'N/A';
    return `$${amount.toFixed(2)}`;
  };

  const formatAssetPath = (path: string) => {
    return path.replace('/home/basedgpt/platform/deployments/envoi/bumble-auto/bumble/', '/assets/');
  };

  const handleActionClick = (action: CallAction) => {
    navigate(`/dashboard/call/${call.call_id}/action/${action.action_id}`);
    setSelectedAction(action);
  };

  const renderActionDetails = (action: CallAction) => {
    let outputData = null;
    try {
      outputData = action.action_output_data ? JSON.parse(action.action_output_data) : null;
    } catch (error) {
      console.error('Error parsing action output data:', error);
    }

    return (
      <Card elevation={3} sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Action Details</Typography>
              <Typography variant="subtitle2">Type: {action.action_type}</Typography>
              <Typography variant="subtitle2">Status: 
                <Chip 
                  label={action.action_status}
                  color={action.action_status === 'error' ? 'error' : 'success'}
                  size="small"
                  sx={{ ml: 1 }}
                />
              </Typography>
              <Typography variant="subtitle2">Timestamp: {formatDate(action.action_timestamp)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>Status Line:</Typography>
              <Typography variant="body2">{action.status_line}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>Input Data:</Typography>
              <Paper sx={{ p: 2, bgcolor: '#f5f5f5' }}>
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(action.action_input_data, null, 2)}
                </pre>
              </Paper>
            </Grid>
            {outputData && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>Output Data:</Typography>
                {outputData.screenshots && (
                  <Box mb={2}>
                    <Typography variant="subtitle2">Screenshots:</Typography>
                    <Grid container spacing={1}>
                      {outputData.screenshots.map((screenshot: string, idx: number) => (
                        <Grid item xs={6} sm={4} md={3} key={idx}>
                          <img 
                            src={formatAssetPath(screenshot)} 
                            alt={`Screenshot ${idx + 1}`} 
                            style={{ width: '100%', height: 'auto', borderRadius: '4px' }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                {outputData.videoPath && (
                  <Box mt={2}>
                    <Typography variant="subtitle2">Video:</Typography>
                    <video controls width="100%" style={{ borderRadius: '4px' }}>
                      <source src={formatAssetPath(outputData.videoPath)} type="video/webm" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <Dialog 
      open={!!call} 
      onClose={onClose} 
      fullScreen
      PaperProps={{ 
        sx: { 
          bgcolor: 'background.default',
          backgroundImage: 'none',
        } 
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Call Details</Typography>
          <IconButton onClick={onClose} edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2, overflowY: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h5" gutterBottom>General Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call ID</Typography>
                    <Typography variant="body1">{call.call_id}</Typography>
                  </Grid>
                  {isSuperUser && 
                  <Grid item xs={12} sm={6} md={4}>
                   <Typography variant="subtitle2" color="textSecondary">Agent ID</Typography>
                    <Typography variant="body1">{call.agent_id || 'N/A'}</Typography>
                  </Grid>}
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call Status</Typography>
                    <Chip label={call.call_status || 'N/A'} color="primary" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call Type</Typography>
                    <Typography variant="body1">{call.call_type || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Start Time</Typography>
                    <Typography variant="body1">{formatDate(call.start_timestamp)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">End Time</Typography>
                    <Typography variant="body1">{formatDate(call.end_timestamp)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Duration</Typography>
                    <Typography variant="body1">{formatDuration(call.call_cost?.total_duration_seconds)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Disconnection Reason</Typography>
                    <Typography variant="body1">{call.disconnection_reason || 'N/A'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {call.call_actions && call.call_actions.length > 0 && (
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                  <Typography variant="h5" gutterBottom>Web Actions</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action Type</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Timestamp</TableCell>
                          <TableCell>Status Line</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {call.call_actions.map((action) => (
                          <TableRow 
                            key={action.action_id}
                            onClick={() => handleActionClick(action)}
                            sx={{ 
                              cursor: 'pointer',
                              bgcolor: action.action_id === selectedAction?.action_id ? '#e3f2fd' : 'inherit',
                              '&:hover': { bgcolor: '#f5f5f5' }
                            }}
                          >
                            <TableCell>{action.action_type}</TableCell>
                            <TableCell>
                              <Chip 
                                label={action.action_status} 
                                color={action.action_status === 'completed' ? 'success' : 'default'}
                                size="small"
                              />
                            </TableCell>
                            <TableCell>{formatDate(action.action_timestamp)}</TableCell>
                            <TableCell>{action.status_line}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            )}

            {selectedAction && renderActionDetails(selectedAction)}

            {isSuperUser &&
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%', boxSizing: 'border-box' }}>
                <Typography variant="h5" gutterBottom>Cost Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Retell Cost</Typography>
                    <Typography variant="h6">{formatCurrency(call.call_cost?.combined_cost)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">OpenAI Cost</Typography>
                    <Typography variant="h6">{formatCurrency(call.estimated_openai_cost)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Combined Cost</Typography>
                    <Typography variant="h6">{formatCurrency((call.call_cost?.combined_cost || 0) + (call.estimated_openai_cost || 0))}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">LLM Model</Typography>
                    <Typography variant="body1">{call.cost_metadata?.llm_model || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Voice Provider</Typography>
                    <Typography variant="body1">{call.cost_metadata?.voice_provider || 'N/A'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Transcript</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {renderTranscript(call.transcript || call.transcript_object || '')}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h5" gutterBottom>Links</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">Recording</Typography>
                    {call.recording_url ? (
                      <a href={call.recording_url} target="_blank" rel="noopener noreferrer">Listen</a>
                    ) : 'N/A'}
                  </Grid>
                  {isSuperUser && <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">Public Log</Typography>
                    {call.public_log_url ? (
                      <a href={call.public_log_url} target="_blank" rel="noopener noreferrer">View</a>
                    ) : 'N/A'}
                  </Grid>}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const renderTranscript = (transcript: string | any[]) => {
  if (!transcript) return <Typography>No transcript available</Typography>;

  if (typeof transcript === 'string') {
    const turns = transcript.split(/(?=User:|Agent:)/g);
    return (
      <Box sx={{ maxHeight: '500px', overflowY: 'auto', p: 2, bgcolor: '#f5f5f5' }}>
        {turns.map((turn, index) => {
          const [speaker, ...contentArr] = turn.trim().split(':');
          const content = contentArr.join(':').trim();
          const isAgent = speaker.toLowerCase() === 'agent';
          return (
            <Box key={index} sx={{ display: 'flex', mb: 2, flexDirection: isAgent ? 'row' : 'row-reverse' }}>
              <Avatar sx={{ bgcolor: isAgent ? '#1976d2' : '#388e3c', mr: isAgent ? 1 : 0, ml: isAgent ? 0 : 1 }}>
                {isAgent ? <SmartToyIcon /> : <PersonIcon />}
              </Avatar>
              <Paper elevation={1} sx={{ 
                p: 2, 
                maxWidth: '70%', 
                bgcolor: isAgent ? '#e3f2fd' : '#fff',
                borderRadius: isAgent ? '20px 20px 20px 5px' : '20px 20px 5px 20px'
              }}>
                <Typography variant="body1">{content}</Typography>
              </Paper>
            </Box>
          );
        })}
      </Box>
    );
  }

  if (Array.isArray(transcript)) {
    return (
      <Box sx={{ maxHeight: '500px', overflowY: 'auto', p: 2, bgcolor: '#f5f5f5' }}>
        {transcript.map((item, index) => {
          const isAgent = item.role.toLowerCase() === 'agent';
          return (
            <Box key={index} sx={{ display: 'flex', mb: 2, flexDirection: isAgent ? 'row' : 'row-reverse' }}>
              <Avatar sx={{ bgcolor: isAgent ? '#1976d2' : '#388e3c', mr: isAgent ? 1 : 0, ml: isAgent ? 0 : 1 }}>
                {isAgent ? <SmartToyIcon /> : <PersonIcon />}
              </Avatar>
              <Paper elevation={1} sx={{ 
                p: 2, 
                maxWidth: '70%', 
                bgcolor: isAgent ? '#e3f2fd' : '#fff',
                borderRadius: isAgent ? '20px 20px 20px 5px' : '20px 20px 5px 20px'
              }}>
                <Typography variant="body1">{item.content}</Typography>
              </Paper>
            </Box>
          );
        })}
      </Box>
    );
  }

  return <Typography>Invalid transcript format</Typography>;
};

export default CallDetails;
