import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Call } from '../types/Call';
import CallTable from './CallTable';
import CallDetails from './CallDetails';
import ExpandedChart from './ExpandedChart';
import { CircularProgress } from '@mui/material';
import { dataManager } from '../dataManager';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import RefreshIcon from '@mui/icons-material/Refresh';
import Logout from '@mui/icons-material/Logout';
import CallSummary from './CallSummary';
import { Summary } from '../types';
import './Dashboard.css';

interface AgentAnalytics {
  agentId: string;
  callCount: number;
  totalMinutes: number;
  totalSpend: number;
}

const Dashboard: React.FC = () => {
  const { callId, actionId } = useParams<{ callId?: string; actionId?: string }>();
  const navigate = useNavigate();
  const [calls, setCalls] = useState<Call[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [agentAnalytics, setAgentAnalytics] = useState<AgentAnalytics[]>([]);
  const [selectedCall, setSelectedCall] = useState<Call | null>(null);
  const [expandedChart, setExpandedChart] = useState<{ dataKey: string; title: string; color: string } | null>(null);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCalls, setTotalCalls] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [callSummary, setCallSummary] = useState<Summary | null>(null);

  const fetchCalls = async (page: number) => {
    try {
      setIsLoading(true);
      const data = await dataManager.getCalls(page, searchTerm);
      setCalls(data.calls);
      setCurrentPage(data.page);
      setTotalPages(data.totalPages);
      setTotalCalls(data.total);
      setIsSuperuser(dataManager.getIsSuperuser());
    } catch (error) {
      console.error('Error fetching calls:', error);
      setError('Failed to fetch calls. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCallById = async (id: string) => {
    try {
      setIsLoading(true);
      const call = await dataManager.getCallById(id);
      if (call) {
        setSelectedCall(call);
      } else {
        setError('Call not found');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching call:', error);
      setError('Failed to fetch call details');
      navigate('/dashboard');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSummary = async () => {
    try {
      const summaryData = await dataManager.getCallSummary();
      setCallSummary(summaryData);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  const fetchAllData = async () => {
    await fetchCalls(1);
    await fetchSummary();
  };

  useEffect(() => {
    if (callId) {
      fetchCallById(callId);
    } else {
      fetchAllData();
    }
  }, [callId, searchTerm]);

  useEffect(() => {
    const analytics: { [agentId: string]: AgentAnalytics } = {};
  
    calls.forEach((call) => {
      const agentId = call.agent_id || 'Unknown';
      if (!analytics[agentId]) {
        analytics[agentId] = {
          agentId,
          callCount: 0,
          totalMinutes: 0,
          totalSpend: 0,
        };
      }
  
      analytics[agentId].callCount++;
      
      const callDurationMinutes = call.end_timestamp && call.start_timestamp
        ? (call.end_timestamp - call.start_timestamp) / 60000
        : 0;
      analytics[agentId].totalMinutes += callDurationMinutes;
      
      analytics[agentId].totalSpend += (call.call_cost?.combined_cost || 0) + (call.estimated_openai_cost || 0);
    });
  
    setAgentAnalytics(Object.values(analytics));
  }, [calls]);

  const handleForceUpdate = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    dataManager.logout()
  }

  const handleCallSelect = (call: Call) => {
    setSelectedCall(call);
    navigate(`/dashboard/call/${call.call_id}`);
  };

  const handleChartClick = (dataKey: string, title: string, color: string) => {
    setExpandedChart({ dataKey, title, color });
  };

  const handlePageChange = (newPage: number) => {
    fetchCalls(newPage);
  };

  const handleSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  const handleCloseCallDetails = () => {
    setSelectedCall(null);
    navigate('/dashboard');
  };

  const renderChart = (data: AgentAnalytics[], dataKey: keyof AgentAnalytics, title: string, color: string) => (
    <div className="chart-card" onClick={() => handleChartClick(dataKey, title, color)}>
      <h3 className="chart-title">{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="agentId" tick={{ fontSize: 12 }} interval={0} angle={-45} textAnchor="end" height={60}>
            <Label value="Agent ID" position="insideBottom" offset={-10} />
          </XAxis>
          <YAxis tick={{ fontSize: 12 }}>
            <Label value={title} position="insideLeft" angle={-90} offset={0} style={{ textAnchor: 'middle' }} />
          </YAxis>
          <Tooltip />
          <Legend wrapperStyle={{ fontSize: 12 }} />
          <Bar dataKey={dataKey} fill={color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1 className="dashboard-title">Call Analytics Dashboard</h1>
        <div className="dashboard-actions">
          <input
            type="text"
            className="search-bar"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <button className="btn btn-primary refresh-button" onClick={handleForceUpdate}>
            <RefreshIcon /> Refresh Page
          </button>
          <button className="btn btn-primary refresh-button" onClick={handleLogout}>
            <Logout /> Logout
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <div className="dashboard-charts">
            {renderChart(agentAnalytics, 'callCount', 'Number of Calls', '#4a90e2')}
            {renderChart(agentAnalytics, 'totalMinutes', 'Total Minutes', '#6c5ce7')}
            {isSuperuser && renderChart(agentAnalytics, 'totalSpend', 'Total Spend ($)', '#e74c3c')}
          </div>
          <div className="call-details">
            <div className="call-details-header">
              <h2 className="call-details-title">Call Details</h2>
            </div>
            {isSuperuser && <CallSummary summary={callSummary} />}
            <CallTable 
              calls={calls}
              isLoading={isLoading}
              totalCalls={totalCalls}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              onSearchChange={handleSearchChange}
              searchTerm={searchTerm}
              isSuperuser={isSuperuser}
              onCallSelect={handleCallSelect}
              onForceUpdate={handleForceUpdate}
              summary={callSummary}
            />
          </div>
          {selectedCall && (
            <CallDetails 
              call={selectedCall} 
              onClose={handleCloseCallDetails}
              selectedActionId={actionId}
            />
          )}
          {expandedChart && (
            <ExpandedChart
              open={!!expandedChart}
              onClose={() => setExpandedChart(null)}
              data={agentAnalytics}
              dataKey={expandedChart.dataKey}
              title={expandedChart.title}
              color={expandedChart.color}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
